<template lang="html">
  <div class="candle-form__step-content">
    <FormPaymentMethod
      v-if="$store.state.donationCandles.form.value"
      :donateValue="$store.state.donationCandles.form.value"
      @chooseMethod="nextStep"
      @comeBack="prevStep"
    />
  </div>
</template>

<script>
import FormPaymentMethod from '@/components/Form/FormPaymentMethod.vue';

export default {
  name: 'CandleFormStep4',
  components: {
    FormPaymentMethod,
  },
  methods: {
    nextStep() {
      const { candle_from, candle_for, message } =
        this.$store.state.donationCandles.previewData;
      const params = {
        candle: {
          candle_from,
          candle_for,
          message,
          amount: this.$store.state.donationCandles.form.value,
        },
        postType: 'candles',
      };

      this.$store.commit('donationPayment/setAmount', params.candle.amount);
      this.$store.dispatch('donationPayment/pay', params);
    },
    prevStep() {
      this.$store.commit('donationCandles/setCurrentStep', 3);
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'styles';
</style>
